import { myFetch } from '../../comms';

export const getGroupDetails = async (groupId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/' + groupId, 'GET');	
}

export const getIsMaster = async (groupId, userId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/is_master/' + groupId, 'GET');
}

export const getIsMember = async (groupId, userId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/is_member/' + groupId, 'GET');
}

export const getLatestDocuments = async (groupId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/' + groupId + '/latest_documents', 'GET');
}

export const getCurrentUserGroups = async () => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/current_user', 'GET');
}

export const getPublicGroups = async () => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/public', 'GET');
}

export const getInviteCode = async (groupId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/invite_code/' + groupId, 'GET');
}

export const getGroupMaster = async (groupId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/' + groupId + '/master/', 'GET');
}

export const getGroupMembers = async (groupId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/' + groupId + '/members/', 'GET');
}

export const getGroupTypes = async () => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/types', 'GET');
}

export const postCreateGroup = async (title, description, templateId, type, pictureStyle) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/create', 'POST', { title, description, templateId, type, pictureStyle });
}

export const postGenerateInviteCode = async (groupId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/generate_invite_code/' + groupId, 'POST');
}

export const postJoinGroup = async (code) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/join/', 'POST', { code });
}

export const postGenerateGroupBackground = async (groupId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/' + groupId + '/generate_background', 'POST');
}

export const postTransferCreditsToGroup = async (groupId, credits) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/' + groupId + '/transfer_credits', 'POST', { credits });
}

export const postTransferCreditsToMember = async (groupId, memberId, credits) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/' + groupId + '/transfer_credits/' + memberId, 'POST', { credits });
}

export const putGroup = async (groupId, title, description, masterId, type, templateId, pictureStyle) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/update/' + groupId, 'PUT', { title, description, masterId, type, templateId, pictureStyle });
}

export const deleteGroupWithId = async (groupId) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/groups/delete/' + groupId, 'DELETE');
}
