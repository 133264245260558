import { BtnIcon, SmallIcon } from "./icons";
import closeIcon from "../../assets/img/close-btn.png";
import { useContext } from "react";
import { NavContext } from "../context";


export const pressBtnClass = "hover:brightness-125 hover:cursor-pointer active:brightness-75"

export const TextBtnStd = ({ text, icon, func, title, extraClass }) => {
  return (
    <>
      <button
        onClick={(e) => func(e)}
        title={title}
        className={`${extraClass} font-sans text-base font-normal block rounded-3xl text-center p-1 m-1 min-w-[180px] bg-light
        text-darker ${pressBtnClass}`}
      >
        <BtnIcon icon={icon} />
        <span className="p-1">{text}</span>
      </button>
    </>
  );
};


export const InputBtnStd = ({ text, type, extraClass }) => {
  return (
    <>
      <input
        type={type}
        value={text}
        className={`${extraClass} font-sans text-base font-normal block rounded-3xl text-center p-1 m-1 min-w-[180px] bg-darker 
        text-light border-black border-2 ${pressBtnClass}`}
      />
    </>
  );
};

export const HugeMedievalBtnStd = ({ text, icon, func }) => {
  return (
    <>
      <button
        onClick={(e) => func(e)}
        className={`font-sans text-3xl font-bold text-center pb-1 mb-2 min-w-[230px] w-fit bg-darker text-light border-stone ${pressBtnClass}`}
      >
        <BtnIcon icon={icon} />
        <span className="p-1">{text}</span>
      </button>
    </>
  );
};

export const TextBtnDanger = ({ text, icon, func, extraClass }) => {

  const moreClass = extraClass ? extraClass : "";

  return (
    <>
      <button
        onClick={(e) => func(e)}
        className={`${moreClass} font-sans font-black border-stone bg-future block text-center min-w-[230px] text-light ${pressBtnClass}`}
      >
        <BtnIcon icon={icon} />
        <span className="p-1">{text}</span>
      </button>
    </>
  );
};

export const HrBtnStd = ({ text, icon, func, extraClass }) => {

  extraClass = extraClass ? extraClass : "";

  return (
    <>
      <button
        className={`${extraClass} mx-1 ${pressBtnClass}`}
        onClick={(e) => func(e)}
      >
        <SmallIcon icon={icon} />
        <p className="text-light bg-blend-multiply">{text}</p>
        <hr className="border-future w-full" />
      </button>
    </>
  );
};

export const CloseBtnStd = ({ func, extraClass }) => {
  return (
    <>
      <img
        className={`${extraClass} h-8 inline-block ${pressBtnClass}`}
        src={closeIcon}
        onClick={(e) => func(e)}
        alt="icon"
      />
    </>
  );
};

export const IconBtn = ({ children, func, title, disabled, disabledText, disabledTitle, extraClass}) => {

  const { setShowPopup, setPopupTitle, setPopupContent, setPopupType, setPopupCloseable } = useContext(NavContext);

  const handlePossibleEvent = (e) => {
    if (disabled) {
      setPopupTitle(disabledTitle);
      setPopupContent(disabledText);
      setPopupType("info");
      setShowPopup(true);
      setPopupCloseable(true);
    } else {
      e.preventDefault();
      func(e);
    }
  }

  return (
    <>
      <button
        title={title} 
        onClick={(e) => handlePossibleEvent(e)}
        className={`${extraClass} ${pressBtnClass} fade-in`}
      >
        {children}
      </button>
    </>
  );
};
