import { createContext, useState, useEffect, useCallback } from 'react';
import { getCurrentUser, getAuthenticationStatus, postRegistration, postLogin, postEmailReset } from './comms';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isStaff, setIsStaff] = useState(false);

  const handleGoogleLogin = useCallback(async (e) => {
    e.preventDefault();
    const maxAttempts = 30;
    let attempts = 0;
    const loginWindow =
      window.open(process.env.REACT_APP_API_HOST + "/auth/google");

    const checkLogin = setInterval(async () => {
      attempts++;
      const authStatus = await getAuthenticationStatus();
      if (authStatus.isAuthenticated) {
        clearInterval(checkLogin);
        const user = await getCurrentUser();
        setCurrentUser(user);
        loginWindow.close();
      } else if (attempts > maxAttempts) {
        clearInterval(checkLogin);
        loginWindow.close();
      }
    }, 1000);

    return () => clearInterval(checkLogin);
  }, []);

  const handleLogin = useCallback(async (username, password) => {
    const user = await postLogin(username, password);
    if (user) {
      setCurrentUser(user);
    }
    return user;    
  }, []);

  const handleLogout = useCallback(() => {
    window.location.href = process.env.REACT_APP_API_HOST + "/auth/logout";
  }, []);

  const handleRegistration = useCallback(async (username, password, email) => {
    const user = await postRegistration(username, password, email);
    if (user) {
      setCurrentUser(user);
    }
    return user;
  }, []);
  
  const handleReset = useCallback(async (email) => {
    return await postEmailReset(email);
  }, []);

  useEffect(() => {
    const init = async () => {
      const authStatus = await getAuthenticationStatus();
      if (authStatus.isAuthenticated) {
        const user = await getCurrentUser();
        if (user) {
          setCurrentUser(user);
          setIsStaff(user.staff);
        } 
      } else {
        setCurrentUser(null);
      }
    };
    init();
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, handleReset, setCurrentUser, handleGoogleLogin, handleLogin, handleLogout, handleRegistration, isStaff }}>
      {children}
    </UserContext.Provider>
  );
};