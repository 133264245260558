import { IconBtn } from "./buttons";

export const MenuButton = ({title, func, icon, disabled, disabledText, disabledTitle}) => {
    return (
        <span className="hover:text-light text-light leading-none">          
          <IconBtn func={func} title={title} disabled={disabled} disabledText={disabledText} disabledTitle={disabledTitle}>
            <img className="w-12 h-12" src={icon} alt={title}/>
          </IconBtn>
          <p className="text-center font-sans text-xs leading-none">{title}</p>
        </span>
    );
}

export const Menu = ({buttons}) => {
    return (
        <>        
        <menu className="chain-in-fast flex flex-row justify-center fixed bottom-1 left-1/2 transform -translate-x-1/2">
            {buttons && buttons.map((button, index) => (
                //check if button is not null
                button && <MenuButton key={index} icon={button.icon} func={button.func} title={button.title} disabled={button.disabled} disabledTitle={button.disabledTitle} disabledText={button.disabledText}/>
            ))}
        </menu>
        </>
    );
}
