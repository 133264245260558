import "./App.css";
import { Land } from "./main/index";
import { BrowserRouter as Router } from "react-router-dom";
import { NavProvider } from "./main/context";
import { UserProvider } from "./main/routes/auth/context";

function App() {
  return (
    
    <Router>
      <NavProvider>
        <UserProvider>
        <Land />
        </UserProvider>
      </NavProvider>
    </Router>
  );
}

export default App;