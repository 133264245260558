export default function Blurred({ children }) {
  return (
    <>
      <div
        id="blurrer-container"
        className="flex flex-col fixed top-0 left-0 backdrop-blur-sm w-[100svw] h-[100svh] items-center justify-center"
      >
          {children}
      </div>
    </>
  );
};