import { myFetch } from "../../comms";

export const getAuthenticationStatus = async () => {
  return myFetch(process.env.REACT_APP_API_HOST + '/auth/status', 'GET');
}

export const getCurrentUser = async() => {
  return myFetch(process.env.REACT_APP_API_HOST + '/auth/current_user', 'GET');
}

export const postRegistration = async (username, password, email) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/auth/register', 'POST', { username, password, email });
}

export const postLogin = async (username, password) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/auth/login', 'POST', { username, password });
}

export const postEmailReset = async (email) => {
  return myFetch(process.env.REACT_APP_API_HOST + '/auth/reset', 'POST', { email });
}