export async function myFetch(url, method, request_data, isFormData = false){
    const fetchOptions = {
        credentials: 'include',
        method: method,
        headers: {},
        body: null
    };

    if (!isFormData){
        fetchOptions.headers = {
            'Content-Type': 'application/json'
        };
        fetchOptions.body = JSON.stringify(request_data);
    } else {
        fetchOptions.body = request_data;
    } 
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    if (response.ok) {
        if (process.env.REACT_APP_NODE_ENV === 'development') {
            console.log(url + ': ');
            console.log(data);
        }
        return data;
    } else {
        // Attempt to parse JSON error message
        if (data.message === 'not_authenticated') {
            window.location.href = '/auth';
        } else {
            throw new Error(data.message);
        }
        return null;
    }
}