export const BtnIcon = ({ icon }) => {
    return (
        <>
        {icon && <img
        className="inline-block w-5"
        src={icon}
        alt="icon" />}
        </>
        )
}

export const ProfilePic = ({ currentUser }) => {
    return (
      <img
        className="max-w-[50px] rounded-[50%] object-cover md:mb-2 md:hover:scale-125 self-center ml-2"
        src={
          currentUser?.profilePic !== null &&
          currentUser?.profilePic !== undefined
            ? currentUser?.profilePic
            : "https://thumbs.dreamstime.com/z/black-line-tattoo-d-dice-style-180318047.jpg"
        }
        alt="this is your google-ProfilePic"
      />
    );
  };

export const SmallIcon = ({ icon }) => {
    return (
        <>
        {icon && <img
        className="inline-block pb-1 mx-1 w-5"
        src={icon}
        alt="icon" />}
        </>
        )
}

export const FlourishLight = ({width, height}) => {
    return (
      <svg width={width} height={height} className="mt-1" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 25 Q 25 50, 50 25 T 90 25" fill="none" stroke="#E2E8F0" strokeWidth="2"/>
      <path d="M10 25 Q 25 0, 50 25 T 90 25" fill="none" stroke="#E2E8F0" strokeWidth="2"/>
      </svg>    
    )
  }