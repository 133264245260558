import { NewPopupSection, BottomSection, InnerSection } from './section';
import { CloseBtnStd, TextBtnStd } from './buttons';
import { SmallLegend } from './legend';
import { StoneImageArticle } from './articles';
import Blurred from './blurred';

export default function Popup({popupTitle, popupContent, popupType, popupFunc, showPopup, setShowPopup, closeable}) {

    if (!showPopup) {
        return null;
    }
        
    return (
        <Blurred>
        {(popupType !== "image") ?
        <NewPopupSection legend={popupTitle} close={true} closeFunc={() => setShowPopup(false)}>
            <SmallLegend close={closeable} closeFunc={() => setShowPopup(false)}>
            {popupTitle}
            </SmallLegend>
            {popupType === 'form' ? <>{popupContent}</> :
            <>
                <InnerSection extraClass="text-center">
                {popupContent}
                </InnerSection>
                <BottomSection extraClass="justify-center">
                {popupType === 'yesno' && <><TextBtnStd text="Yes" func={() => {popupFunc(); setShowPopup(false)}} /><TextBtnStd text="No" func={() => setShowPopup(false)} /></>}
                {popupType === 'ok' && <TextBtnStd text="OK" func={() => {popupFunc(); setShowPopup(false)}} />}
                {popupType === 'info' && <TextBtnStd text="OK" func={() => setShowPopup(false)} />}
                </BottomSection>
            </>}
        </NewPopupSection>
        :
        <>
            <article className="relative">
            <StoneImageArticle picture={popupContent} extraClass="w-80 h-80 md:w-[40rem] md:h-[40rem]"/>
            {closeable && <CloseBtnStd func={() => setShowPopup(false)} extraClass="absolute top-0 right-0"/>}
            </article>
        </>
        }
        </Blurred>
    );  
  };