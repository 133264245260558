
import { createContext, useCallback, useContext, useState, useEffect } from 'react';

import { GroupsContext } from '../../../../context';
import { UserContext } from '../../../../../auth/context';

import {
    postCharacter,
    putCharacter,
    deleteCharacter,
    getCharactersWithGroup,
    postGenerateCharacterImage
} from './comms';

export const CharacterContext = createContext();

export const CharacterProvider = ({ children }) => {

    const { selectedGroup } = useContext(GroupsContext);
    const { currentUser } = useContext(UserContext);

    const [groupCharacters, setGroupCharacters] = useState([]);
    const [myCharacterIds, setMyCharacterIds] = useState([]);
    const [selectedCharacter, setSelectedCharacter] = useState(null);

    const createCharacter = useCallback(async (title, description, extraFields) => {
        const character = await postCharacter(title, description, extraFields, selectedGroup.id);
        setSelectedCharacter(character);
        if (character) {
            setGroupCharacters(groupCharacters => [...groupCharacters, character]);
            setMyCharacterIds(myCharacterIds => [...myCharacterIds, character.id]);
            return character;
        }        
    }, [selectedGroup]);

    const updateCharacter = useCallback(async (id, title, description, extraFields) => {
        const uCharacter = await putCharacter(id, title, description, extraFields, selectedGroup.id);
        setSelectedCharacter(uCharacter);
        if (uCharacter) {
            setGroupCharacters(groupCharacters.map(character => character.id === id ? uCharacter : character));          
            return uCharacter;
        }
    }, [
        selectedGroup,
        groupCharacters
    ]);

    const selectCharacter = useCallback(async (id) => {
        const character = groupCharacters.find(character => character.id === id);
        setSelectedCharacter(character);
        return character;
    }, [groupCharacters]);

    const removeCharacter = useCallback(async (id) => {
        const data = await deleteCharacter(selectedGroup.id, id);
        setSelectedCharacter(null);
        if (data.message === 'Character deleted.') {
            const tGroupCharacters = groupCharacters.filter(character => character.id !== id);
            setGroupCharacters(tGroupCharacters);
            setMyCharacterIds(myCharacterIds.map(characterId => characterId !== id ? characterId : null));
            return true;
        }        
    }, [
        selectedGroup,
        groupCharacters,
        myCharacterIds
    ]);

    const generateCharacterImage = async (characterId) => {
        const file = await postGenerateCharacterImage(characterId);
        if (file) {
            await refreshCharacters();
            return file;
        }
    };    

    const refreshCharacters = useCallback(async () => {
        const characters = await getCharactersWithGroup(selectedGroup.id);
        setGroupCharacters(characters);
        const myCharacters = characters.filter( (character) => character.userId === currentUser.id);
        setMyCharacterIds(myCharacters.map(character => character.id));
        return characters;
    }, [selectedGroup, currentUser]);

    useEffect(() => {
        if (selectedGroup && currentUser) {
            refreshCharacters();
        }
    }, [
        currentUser,
        selectedGroup,
        refreshCharacters
    ]);

    return (
        <CharacterContext.Provider value={{
            groupCharacters,
            myCharacterIds,
            createCharacter,
            updateCharacter,
            selectCharacter,
            selectedCharacter,
            removeCharacter,
            generateCharacterImage
        }}>
            {children}
        </CharacterContext.Provider>
    );
}