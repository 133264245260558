import { createContext, useContext, useEffect, useCallback, useState } from 'react';

import {
    getStory,
    getEntries,
    getStoryTypes,
    getStoriesWithGroup,
    getLastEntry,
    postStory,
    putStory,
    postEntryWithGroup,
    putEntry,
    postGenerateEntryImage,
    postMakeItEpic,
    patchArchiveEntry,
    deleteStory,
    deleteFileWithEntry
} from './comms';

import { GroupsContext } from '../../../../context';
import { UserContext } from '../../../../../auth/context';

export const StoryContext = createContext();

export const StoryProvider = ({ children }) => {
    const { selectedGroup, refreshLatestDocuments } = useContext(GroupsContext);
    const { currentUser } = useContext(UserContext);

    const [groupStories, setGroupStories] = useState([]);
    const [myStoryIds, setMyStoryIds] = useState([]);
    const [storyTypes, setStoryTypes] = useState([]);
    const [entries, setEntries] = useState([]);
    const [selectedStory, setSelectedStory] = useState(null);

    const remakeLastEntry = useCallback(async (entry) => {
        setGroupStories(groupStories.map(story =>
            story.id === entry.storyId && (!story.lastEntry || story.lastEntry?.id <= entry.id) ?
            {
                ...story,
                lastEntry: entry,
                updatedAt: entry.updatedAt
            } : story
        ).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)));
    }, [groupStories]);    

    const selectStory = useCallback(async (storyId) => {
        if (selectedGroup) {
            const story = await getStory(storyId, selectedGroup.id);
            setSelectedStory(story);
            return story;
        }
    }, [selectedGroup]);

    const createStory = useCallback(async (title, type, characterOwnerId) => {
        const story = await postStory(title, type, selectedGroup.id, characterOwnerId);
        setSelectedStory(story);
        if (story) {
            setGroupStories(groupStories => [story, ...groupStories]);
        }        
        return story;
    }, [selectedGroup]);

    const updateStory = useCallback(async (storyId, title, type, characterOwnerId) => {
        const story = await putStory(storyId, title, type, characterOwnerId);
        console.log(story);
        setSelectedStory(story);
        if (story) {
            setGroupStories(groupStories.map(storyM => storyM.id === storyId ? story : storyM));
        }
        return story;
    }, [groupStories]);

    const updateEntry = useCallback(async (entryId, characterId, content, extraFields, files, mentionedCharactersIds) => {
        const entry = await putEntry(entryId, characterId, content, extraFields, files, mentionedCharactersIds);
        if (entry) {
            setEntries(entries.map(entryM => entryM.id === entryId ? entry : entryM));
            await refreshLatestDocuments(selectedGroup.id);
            await remakeLastEntry(entry);
            return entry;
        }
    }, [
        entries,
        refreshLatestDocuments,
        remakeLastEntry,
        selectedGroup
    ]);

    const selectEntries = useCallback(async (storyId, page, limit, order) => {
        const { currentPage, totalPages, totalEntries, entries } = await getEntries(storyId, selectedGroup.id, page, limit, order);
        if (entries) {
            setEntries(entries);
            return { currentPage, totalPages, totalEntries, entries };
        }
    }, [selectedGroup]);


    const createEntry = useCallback(async (characterId, storyId, content, extraFields, files, mentionedCharactersIds) => {
        const entry = await postEntryWithGroup(selectedGroup.id, storyId, characterId, content, extraFields, files, mentionedCharactersIds);
        if (entry) {
            setEntries([entry, ...entries]);
            await refreshLatestDocuments(selectedGroup.id);
            await remakeLastEntry(entry);
            return entry;
        }
    }, [
        selectedGroup,
        entries,
        refreshLatestDocuments,
        remakeLastEntry
    ]);

    const removeStory = useCallback(async (storyId) => {
        const data = await deleteStory(storyId);
        if (data.message === 'Story deleted.') {
            setGroupStories(groupStories.filter(story => story.id !== storyId));
            setMyStoryIds(myStoryIds.filter(id => id !== storyId));
            setSelectedStory(null);
            return true;
        }
    }, [groupStories, myStoryIds]);

    const deleteEntry = useCallback(async (entryId) => {
        if (entryId) {
            const entry = await patchArchiveEntry(entryId);
            if (entry) {
                setEntries(entries.filter(entry => entry.id !== entryId));
                await refreshLatestDocuments(selectedGroup.id);
                const newLastEntry = await getLastEntry(selectedGroup.id, entry.storyId);
                setGroupStories(groupStories.map(story => story.id === entry.storyId ? { ...story, lastEntry: newLastEntry } : story));
                return entry;
            }
        }               
    }, [
        entries,
        refreshLatestDocuments,
        selectedGroup,
        groupStories
    ]);

    const deleteFileFromEntry = useCallback(async (entryId, fileId) => {
        const entry = await deleteFileWithEntry(entryId, fileId);
        if (entry) { 
            setEntries(entries.map(entry => entry.id === entryId ? entry : entry));
            await refreshLatestDocuments(selectedGroup.id);
            await remakeLastEntry(entry);
            return entry;
        }
    }, [
        entries,
        refreshLatestDocuments,
        remakeLastEntry,
        selectedGroup
    ]);

    const refreshStories = useCallback(async () => {
        if (currentUser) {
            const stories = await getStoriesWithGroup(selectedGroup.id);
            setGroupStories(stories.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)));
            const myStories = stories.filter( (story) => story.userId === currentUser.id);
            setMyStoryIds(myStories.map(story => story.id));
            return stories; 
        }      
    }, [selectedGroup, currentUser]);

    const refreshStoryTypes = useCallback(async () => {
        const storyTypes = await getStoryTypes();
        setStoryTypes(storyTypes);
    }, []);

    const generateEntryImage = useCallback(async (entryId) => {
        try {
            const file = await postGenerateEntryImage(entryId);            
            if (file) {
                setEntries(entries.map(entry => 
                    entry.id === entryId 
                    ? { 
                        ...entry,
                        image: file,
                        files: entry.files ? entry.files.map(fileM => fileM.fileTitle === 'description' ? file : fileM) : [file]
                    } 
                    : entry
                ));
                await refreshLatestDocuments(selectedGroup.id);
                const entry = entries.find(entry => entry.id === entryId);
                if (entry)
                    await remakeLastEntry(entry);
                else {
                    await refreshStories();
                }
                return file;
            }        
        } catch (error) {
            if (error.message === "cant_complete_request") {
                alert("Something with your request went wrong. What did you ask for?");
            } else {
                throw error;
            }
            // Handle error appropriately
        }
    }, [
        entries,
        setEntries,
        refreshLatestDocuments,
        remakeLastEntry,
        refreshStories,
        selectedGroup
    ]);

    const makeItEpic = useCallback(async (characterId, content) => {
        const response = await postMakeItEpic(characterId, content);
        return response;
    }, []);

    useEffect(() => {  
        if (currentUser) {
            refreshStoryTypes();
        }
    }, [
        currentUser,
        refreshStoryTypes
    ]);

    useEffect(() => {
        if (selectedGroup) {
            refreshStories();
        }
    }, [selectedGroup, refreshStories]);


    return (
        <StoryContext.Provider value={{
            groupStories,
            myStoryIds,
            storyTypes,
            selectedStory,
            createStory,
            updateStory,
            selectStory,
            selectEntries,
            updateEntry,
            createEntry,
            deleteEntry,
            deleteFileFromEntry,
            generateEntryImage,
            makeItEpic,
            entries,
            removeStory
        }}>
            {children}
        </StoryContext.Provider>
    );
}