import { createContext, useCallback, useEffect, useState, useContext } from 'react';
import {
    getTemplate,
    putTemplate,
    getTemplates,
    getTemplatePropertyTypes,
    getTemplateMaster
} from './comms';
import { UserContext } from '../auth/context';


export const TemplatesContext = createContext();

export const TemplatesProvider = ({ children }) => {
    
    const [approvedTemplates, setApprovedTemplates] = useState([]);
    const [templatePropertyTypes, setTemplatePropertyTypes] = useState([]);
    const [templateMasterId, setTemplateMasterId] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const { currentUser } = useContext(UserContext);

    const updateTemplate = async (templateId, title, json, custom) => {
        const template = await putTemplate(templateId, title, json, custom);
        setSelectedTemplate(template);
        if (template) {
            if (!custom){
                setApprovedTemplates(approvedTemplates.map((t) => t.id === template.id ? template : t));
            }
        }
        return template;
    };

    const selectTemplate = useCallback(async (templateId) => {
        const template = await getTemplate(templateId);
        setSelectedTemplate(template);
        if (template) {
            const master = await getTemplateMaster(templateId);
            setTemplateMasterId(master);
        }
        return template;
    }, []);

    const refreshTemplates = useCallback(async () => {        
        const templates = await getTemplates();
        setApprovedTemplates(templates);
    }, [
        setApprovedTemplates
    ]);

    useEffect(() => {
        if (currentUser) {
            refreshTemplates();
            getTemplatePropertyTypes().then(templatePropertyTypes => {
                setTemplatePropertyTypes(templatePropertyTypes);
            });
        }
    }, [
        refreshTemplates,
        currentUser
    ]);

    return (
        <TemplatesContext.Provider value={{
            approvedTemplates,
            templatePropertyTypes,
            updateTemplate,
            templateMasterId,
            selectTemplate,
            refreshTemplates,
            selectedTemplate
        }}>
            {children}
        </TemplatesContext.Provider>
    )
};