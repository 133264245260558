import { myFetch } from '../../comms';

export const getTemplates = async () => {
    return myFetch(process.env.REACT_APP_API_HOST + '/templates', 'GET');
}

export const getTemplate = async (templateId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/templates/' + templateId, 'GET');
}

export const getTemplatePropertyTypes = async () => {
    return myFetch(process.env.REACT_APP_API_HOST + '/templates/propertytypes', 'GET');
}

export const getTemplateMaster = async (templateId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/templates/' + templateId + '/master', 'GET');
}

export const putTemplate = async (templateId, title, json, custom) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/templates/' + templateId, 'PUT', { title, json, custom });
}