import { myFetch } from '../../../../../../comms';

export const postCharacter = async (title, description, extraFields, groupId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/characters/group/' + groupId + '/create', 'POST', { title, description, extraFields });
  }

export const putCharacter = async (characterId, title, description, extraFields, groupId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/characters/group/' + groupId + '/update/' + characterId , 'PUT', { title, description, extraFields });
  }

export const deleteCharacter = async (groupId, characterId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/characters/group/' + groupId + '/delete/' + characterId, 'DELETE');
  }
  
export const getCharactersWithGroup = async (groupId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/characters/group/' + groupId, 'GET');
  }
  
export const postGenerateCharacterImage = async (characterId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/characters/' + characterId + '/generate_image', 'POST');
  }

  