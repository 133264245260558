import { myFetch } from '../../../../../../comms';

export const getStory = async (storyId, groupId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/' + storyId + '/group/' + groupId, 'GET');	
}

export const getEntries = async (storyId, groupId, page, limit, order) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/entries/group/' + groupId + '/story/' + storyId + '?page=' + page + '&limit=' + limit + '&order=' + order, 'GET');
}

export const getStoryTypes = async () => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/types', 'GET');
}

export const getLastEntry = async (groupId, storyId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/' + storyId + '/group/' + groupId + '/get_last_entry', 'GET');
}

export const getStoriesWithGroup = async (groupId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/group/' + groupId, 'GET');
}

export const postMakeItEpic = async (characterId, content) => {
    return myFetch(process.env.REACT_APP_API_HOST + `/stories/entries/make_it_epic`, 'POST', { characterId, content });
}

export const postStory = async (title, type, groupId, characterOwnerId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/create/group/' + groupId, 'POST', { title, type, characterOwnerId });
}

const buildEntryFormData = (data) => {
    const { characterId, content, extraFields, files, mentionedCharactersIds } = data;
    const formData = new FormData();
    formData.append('characterId', characterId);
    formData.append('content', content);
    formData.append('extraFields', JSON.stringify(extraFields || {}));
    for (let file of files) {
        formData.append('files', file);
    }
    formData.append('mentionedCharactersIds', JSON.stringify(mentionedCharactersIds || {}));
    return formData;
}

export const postEntryWithGroup = async (groupId, storyId, characterId, content, extraFields, files, mentionedCharactersIds) => {
    const formData = buildEntryFormData({ characterId, content, extraFields, files, mentionedCharactersIds });
    formData.append('storyId', storyId);
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/entries/create/group/' + groupId, 'POST', formData, true);
}

export const putEntry = async (entryId, characterId, content, extraFields, files, mentionedCharactersIds) => {
    const formData = buildEntryFormData({ characterId, content, extraFields, files, mentionedCharactersIds });
    return myFetch(`${process.env.REACT_APP_API_HOST}/stories/entries/${entryId}`, 'PUT', formData, true);
};

export const putStory = async (storyId, title, type, characterOwnerId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/' + storyId, 'PUT', { title, type, characterOwnerId });
}

export const postGenerateEntryImage = async (entryId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/entries/' + entryId + '/generate_image', 'POST');
}

export const patchArchiveEntry = async (entryId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/entries/archive/' + entryId, 'PATCH');
}

export const deleteStory = async (storyId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/delete/' + storyId, 'DELETE');
}

export const deleteFileWithEntry = async (entryId, fileId) => {
    return myFetch(process.env.REACT_APP_API_HOST + '/stories/entries/' + entryId + '/files/' + fileId, 'DELETE');
}  