import { InvisibleSection } from '../components/section';
import { HugeMedievalBtnStd, HrBtnStd } from '../components/buttons';
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { NavContext } from '../context';
import bg from '../../assets/img/newBG.webp';

export default function MainMain() {

    const { 
      setMenu,
      setBg,
      setLeftSidebar,
      setRightSidebar
     } = useContext(NavContext);

    const navigate = useNavigate();
    useEffect(() => {
          setLeftSidebar(null);
          setRightSidebar(null);
          setMenu([]);
          setBg(bg);
      }, [
        setMenu,
        setBg,
        setLeftSidebar,
        setRightSidebar
      ]);    

    const animations = [
        { text: "Welcome to Documents And Dragons, the ultimate platform for your roleplaying group.", delay: 1500 },
        { text: "Dive in to worlds without bounds.", delay: 3000 },
        { text: "Chronicle your character's exploits.", delay: 4500 },
        { text: "Bring your stories to life.", delay: 6000 }
    ];

    return (
          <InvisibleSection legend="Welcome">
              <h1
                className="chain-in font-superserif text-light text-center font-bold p-4 text-6xl md:text-7xl"
              >
                Documents and Dragons
              </h1>
              {animations.map((item, index) => (
                  <p
                      key={index}
                      className="fade-in-slow p-2 text-center text-base md:text-xl"
                      style={{ animationDelay: `${item.delay}ms` }}
                  >
                      {item.text}
                  </p>
              ))}
              <span className="fade-in-superslow p-2 text-center text-base md:text-xl" style={{'animationDelay': '6500ms'}}><HugeMedievalBtnStd text="Dive in" func={() => navigate("/getstarted")}/></span>
              <span className="font-superserif text-sm mb-8 "><HrBtnStd text="Skip >" func={() => navigate("/getstarted")}/></span>
          </InvisibleSection>
    );
}